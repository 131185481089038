"use client";

import { RouteError } from "types/common";
import CustomErrorComponent from "components/CustomError";

// TODO: Work message and ui for this error
const LoginError = ({ error, reset }: RouteError) => (
  <CustomErrorComponent
    error={error}
    reset={reset}
    title="Something went wrong in Login View! Please refresh the page or contact us."
  />
);

export default LoginError;
